import React from "react"
import { PageProps } from "gatsby"
import i18n from "i18next"
import SEO from "../../../components/layout/seo"
import { useCustomTranslation } from "../../../i18n-hook"
import { Comments } from "../../../components/core/comments"
import translationFr from "../../../locales/fr/asia/japan/customs-in-japan.json"
import translationEn from "../../../locales/en/asia/japan/customs-in-japan.json"
import { JapanBlogLayout, JapanHeadline, JapanTitle } from "../../../components/core/japan/japan"
import { Introduction, SectionContent } from "../../../components/core/section"
import { Conclusion } from "../../../components/core/conclusion"
import { Divider } from "../../../components/core/divider"
import { Quote } from "../../../components/core/quote"
import HomeImgUrl from "../../../images/asia/japan/home.jpg"
import { ImageAsLandscape, ImageAsPortrait } from "../../../components/images/layout"
import { SharedCardJapanImages } from "../../../components/images/asia/japan/shared-card-japan-images"
import styled from "@emotion/styled"
import { CustomsInJapanImages } from "../../../components/images/asia/japan/customs-in-japan"

const Shinkansen = () => (
  <svg height="24" viewBox="0 0 512 512" width="24">
    <g>
      <g>
        <path
          d="m245 11c-85.429 0-160.631 43.733-204.478 110.023l204.478 379.977c49.56 0 95.67-14.71 134.22-40 66.72-43.78 110.78-119.24 110.78-205 0-135.31-109.69-245-245-245z"
          fill="#ff72c3"
        />
      </g>
      <g>
        <circle cx="260.884" cy="236.632" fill="#ff94d2" r="200" />
      </g>
      <g>
        <path
          d="m63.9 421c13.88 15.218 29.651 28.674 46.94 40.01l63.104 11.05h308.526l-53.86-53.86-10.9-10.9-340.045-.065z"
          fill="#bed3e3"
        />
      </g>
      <g>
        <path
          d="m63.903 420.997c13.882 15.224 29.663 28.68 46.96 40.018l63.075 11.044h39.085v-11.059c-11.046 0-20-8.954-20-20s8.954-20 20-20v-13.742l-135.353-.028z"
          fill="#85a6d3"
        />
      </g>
      <g>
        <path
          d="m512 481c0 11.05-8.95 20-20 20h-247c-49.56 0-95.67-14.71-134.22-40h381.22c5.52 0 10.52 2.24 14.14 5.86s5.86 8.62 5.86 14.14z"
          fill="#8f766c"
        />
      </g>
      <g>
        <path
          d="m505.87 344.26c-3.96-7.44-9.73-13.93-16.93-18.78l-7.5-5.05-104.21-70.1c-19.02-12.8-35.8-28.65-49.67-46.91-39.41-51.93-100.84-82.42-166.03-82.42h-121.02c-8.314 12.571-15.492 25.958-21.406 40.007v44.659l-19.032 44.666c-.043 1.885-.072 3.773-.072 5.668 0 11.654.821 23.115 2.395 34.335l.011-.003 20.892 20-11.804 20-.013-.002c2.163 6.802 4.621 13.472 7.348 20.002h.002l14.68 10-5.233 10-.007-.001c9.728 18.4 21.724 35.418 35.629 50.669h395.9c28.83 0 52.2-23.37 52.2-52.2 0-8.69-2.16-17.1-6.13-24.54z"
          fill="#fff5f5"
        />
      </g>
      <g>
        <path
          d="m426.14 420.95c-181.7-2.63-328.46-141.35-328.46-299.95h-57.17c-8.314 12.571-15.492 25.958-21.406 40.007v44.659l-19.032 44.666c-.043 1.885-.072 3.773-.072 5.668 0 11.654.821 23.115 2.395 34.335l.011-.003 20.892 20-11.804 20-.013-.002c2.163 6.802 4.621 13.472 7.348 20.002h.002l14.68 10-5.233 10-.007-.001c9.728 18.4 21.724 35.418 35.629 50.669h367.51c-1.76 0-3.52-.01-5.27-.05z"
          fill="#e1ebf0"
        />
      </g>
      <g>
        <path
          d="m181.53 161h106.373c49.337 0 89.332 39.995 89.332 89.332h-146.373c-38.291 0-69.332-31.041-69.332-69.332 0-11.046 8.954-20 20-20z"
          fill="#8f766c"
        />
      </g>
      <g>
        <path
          d="m221.53 181c0-11.046 8.954-20 20-20h-60c-11.046 0-20 8.954-20 20 0 38.291 31.041 69.332 69.332 69.332h60c-38.291 0-69.332-31.041-69.332-69.332z"
          fill="#77625a"
        />
      </g>
      <g>
        <path
          d="m59.332 161h-40.228c-11.609 27.571-18.314 57.712-19.032 89.332h59.26c8.284 0 15-6.716 15-15v-59.332c0-8.284-6.716-15-15-15z"
          fill="#77625a"
        />
      </g>
      <g>
        <path
          d="m319.729 290.332h-317.323c1.926 13.731 4.984 27.097 9.088 40h239.972c6.21 0 12.334-1.446 17.889-4.223l52.61-26.305c4.719-2.36 3.04-9.472-2.236-9.472z"
          fill="#76b6fa"
        />
      </g>
      <g>
        <path
          d="m2.406 290.332c1.926 13.731 4.984 27.097 9.088 40h183.238c-13.52-12.383-25.868-25.775-36.898-40z"
          fill="#55a4f9"
        />
      </g>
      <g>
        <path
          d="m160.245 350.332h-141.414c2.852 6.83 6.01 13.5 9.446 20h106.066c4.657 0 9.251-1.084 13.416-3.167l14.721-7.361c4.72-2.36 3.041-9.472-2.235-9.472z"
          fill="#55a4f9"
        />
      </g>
      <g>
        <path
          d="m225 481c0-11.046 8.954-20 20-20h-134.22c38.55 25.29 84.66 40 134.22 40-11.046 0-20-8.954-20-20z"
          fill="#77625a"
        />
      </g>
    </g>
  </svg>
)

const Receive = () => (
  <svg height="512" viewBox="0 0 512 512" width="512">
    <g>
      <g>
        <path
          d="m111.879 74.671c0 88.952 123.76 158.494 141.774 167.757 1.476.759 3.217.759 4.693 0 18.014-9.262 141.775-75.652 141.775-167.757.001.001.002.002.003.002-.138-1.501-.308-3.007-.515-4.519-12.768-93.332-125.793-84.112-143.609-27.259-17.817-56.853-130.841-66.073-143.609 27.259-.207 1.513-.377 3.02-.515 4.521.001-.002.002-.003.003-.004z"
          fill="#da4a54"
        />
        <path
          d="m253.654 233.428c-18.014-9.262-133.877-72.056-141.774-158.757-8.834 96.192 122.441 167.395 141.762 177.331 1.484.763 3.233.763 4.718 0 19.321-9.936 150.596-81.139 141.762-177.331-7.898 86.701-123.76 149.494-141.775 158.757-1.476.759-3.218.759-4.693 0z"
          fill="#d82e3d"
        />
        <g>
          <g>
            <path
              d="m68.633 175.919 11.065 80.081c.81 2.882 3.887 4.452 6.709 3.455 4.045-1.428 21.894-10.137 21.894-10.137v-60.65c0-12.092-9.802-21.894-21.893-21.894-7.334 0-13.82 3.609-17.793 9.145.005.001.012 0 .018 0z"
              fill="#edbca4"
            />
            <path
              d="m85.59 293.478.007.009c-1.802-8.235.865-17.166 7.715-23.024 4.353-3.723 9.672-5.582 14.988-5.662v-76.132c0-12.092-9.802-21.893-21.893-21.893-12.091 0-21.893 9.802-21.893 21.893v29.82z"
              fill="#edbca4"
            />
            <path
              d="m108.301 249.318c-4.347.04-8.628.802-12.673 2.231-2.823.997-5.9-.573-6.71-3.455l-20.286-72.175c-2.582 3.593-4.119 7.987-4.119 12.749v47.901l19.886 74.251 23.901-46.02v-15.482z"
              fill="#eaab92"
            />
            <path
              d="m228.907 397.348c-4.225-48.684-57.998-73.362-57.998-73.362l-44.411-51.071c-8.512-9.789-23.326-10.883-33.184-2.452-6.85 5.859-9.517 14.79-7.715 23.025l-.007-.01-32.914-117.099c-3.485-12.398-15.164-20.35-27.611-19.512-12.354 3.959-16.77 14.818-14.815 23.425l25.689 113.147c4.089 18.009 11.206 35.193 21.047 50.819l48.245 76.603c8.075 12.822 12.36 27.665 12.36 42.818v39.681l9 8.64h79.1c2.85 0 5.161-2.311 5.161-5.161v-30.043c0-11.641 2.804-23.11 8.227-33.411 5.52-10.486 11.5-26.737 9.826-46.037z"
              fill="#ffd6bd"
            />
            <g fill="#f9c6aa">
              <path d="m102.047 286.462c-4.487-5.298-6.212-11.997-5.365-18.381-1.169.691-2.303 1.47-3.37 2.382-9.882 8.452-11.077 23.302-2.672 33.225l42.242 49.873c8.572-6.667 9.862-19.134 2.836-27.414z" />
              <path d="m108.013 506.839v-33.87c0-15.152-4.285-29.996-12.36-42.818l-48.245-76.603c-9.842-15.626-16.958-32.81-21.047-50.819l-25.689-113.147c-3.254-14.332 5.625-28.615 19.917-32.04 1.496-.358 2.992-.576 4.479-.676-5.343 6.23-7.77 14.818-5.815 23.425l25.689 113.147c4.089 18.009 11.206 35.193 21.047 50.819l48.245 76.603c8.075 12.822 12.36 27.665 12.36 42.818v48.322h-13.419c-2.852 0-5.162-2.311-5.162-5.161z" />
            </g>
          </g>
          <g>
            <path
              d="m443.367 175.919-11.065 80.081c-.81 2.882-3.887 4.452-6.709 3.455-4.045-1.428-21.893-10.137-21.893-10.137v-60.65c0-12.092 9.802-21.894 21.893-21.894 7.333 0 13.82 3.609 17.793 9.145-.006.001-.013 0-.019 0z"
              fill="#edbca4"
            />
            <path
              d="m426.41 293.478-.008.009c1.802-8.235-.865-17.166-7.715-23.024-4.353-3.723-9.672-5.582-14.988-5.662v-76.132c0-12.092 9.802-21.893 21.893-21.893 12.091 0 21.893 9.802 21.893 21.893v29.82z"
              fill="#edbca4"
            />
            <path
              d="m403.699 249.318c4.347.04 8.628.802 12.673 2.231 2.823.997 5.9-.573 6.71-3.455l20.286-72.175c2.582 3.593 4.119 7.987 4.119 12.749v47.901l-19.886 74.251-23.901-46.02v-15.482z"
              fill="#eaab92"
            />
            <path
              d="m283.093 397.348c4.225-48.684 57.998-73.362 57.998-73.362l44.411-51.071c8.513-9.789 23.326-10.883 33.184-2.452 6.85 5.859 9.517 14.79 7.715 23.025l.008-.01 32.912-117.099c3.485-12.398 15.164-20.35 27.611-19.512 12.354 3.959 16.77 14.818 14.815 23.425l-25.689 113.147c-4.089 18.009-11.206 35.193-21.047 50.819l-48.245 76.603c-8.075 12.822-12.36 27.665-12.36 42.818v39.681l-9 8.64h-79.1c-2.85 0-5.161-2.311-5.161-5.161v-30.043c0-11.641-2.804-23.11-8.227-33.411-5.519-10.486-11.5-26.737-9.825-46.037z"
              fill="#ffd6bd"
            />
            <g fill="#f9c6aa">
              <path d="m409.953 286.462c4.487-5.298 6.212-11.997 5.365-18.381 1.169.691 2.303 1.47 3.37 2.382 9.882 8.452 11.077 23.302 2.672 33.225l-42.242 49.873c-8.572-6.667-9.862-19.134-2.836-27.414z" />
              <path d="m403.988 506.839v-33.87c0-15.152 4.285-29.996 12.36-42.818l48.245-76.603c9.842-15.626 16.958-32.81 21.047-50.819l25.689-113.147c3.254-14.332-5.625-28.615-19.917-32.04-1.496-.358-2.992-.576-4.479-.676 5.343 6.23 7.77 14.818 5.815 23.425l-25.689 113.147c-4.089 18.009-11.205 35.193-21.047 50.819l-48.245 76.603c-8.075 12.822-12.36 27.665-12.36 42.818v48.322h13.419c2.851 0 5.162-2.311 5.162-5.161z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const Sushi = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
    <circle fill="#5FB795" cx="256" cy="256" r="256" />
    <path
      fill="#FFC34E"
      d="M487.414,365.598c-10.282,21.692-23.531,41.702-39.215,59.507L253.346,242.05L75.254,74.72
l-5.35-5.026l-30.25-28.411c-9.164-8.61-8.6-23.374,1.264-31.169c0.104-0.084,0.219-0.167,0.324-0.251
c7.743-6.102,18.683-5.966,26.196,0.408l42.099,35.746l26.77,22.727l21.107,17.607L487.414,365.598z"
    />
    <path
      fill="#EA9B28"
      d="M458.919,412.086c-3.427,4.462-7.011,8.798-10.721,13.019L253.346,242.05L75.254,74.72l-5.35-5.026
l-30.25-28.411c-9.164-8.61-8.6-23.374,1.264-31.169c0.104-0.084,0.219-0.167,0.324-0.251c3.48-2.738,7.596-4.211,11.766-4.43
c-6.217,8.15-5.548,20.093,2.32,27.491l30.25,28.411l1.996,1.881L269.019,233.69L458.919,412.086z"
    />
    <path
      fill="#EF4487"
      d="M387.958,127.086c7.907-4.002,10.426-14.21,5.099-21.291c-0.034-0.046-0.069-0.092-0.103-0.138
l-13.099-17.349c-41.674-55.201-116.711-72.596-178.422-41.362l-99.618,50.419C33.97,131.702,0.449,209.694,22.222,282.55
l2.789,9.332c0.017,0.054,0.032,0.11,0.049,0.164c2.552,8.487,12.269,12.501,20.176,8.499L387.958,127.086z"
    />
    <path
      fill="#CED0D1"
      d="M366.423,126.505c0.992-3.341,0.73-7.025-0.905-10.256c-0.372-0.735-0.817-1.445-1.329-2.122
c-1.351-1.799-3.05-3.167-4.94-4.098c-1.89-0.931-3.954-1.419-6.037-1.443c0.686-3.101,0.297-6.395-1.178-9.309
c-0.578-1.141-1.319-2.213-2.23-3.199c-1.674-1.793-3.701-3.051-5.873-3.754c-2.178-0.714-4.5-0.872-6.741-0.476
c0.068-2.333-0.442-4.658-1.499-6.748c-1.006-1.988-2.521-3.776-4.487-5.137c-2.011-1.395-4.258-2.188-6.541-2.408
c-2.272-0.227-4.586,0.108-6.688,0.973c-0.25-1.307-0.675-2.567-1.276-3.753c-1.452-2.869-3.905-5.246-7.116-6.573
c-3.897-1.618-8.115-1.313-11.606,0.453c-0.554,0.28-1.101,0.6-1.614,0.959c-0.126-0.305-0.258-0.593-0.406-0.887
c-1.904-3.762-5.496-6.627-9.968-7.415c-3.105-0.557-6.139-0.044-8.748,1.277c-1.446,0.731-2.754,1.706-3.87,2.895
c-1.311-1.862-3.063-3.389-5.1-4.415c-2.044-1.038-4.362-1.581-6.802-1.495c-2.077,0.072-4.034,0.608-5.774,1.489
c-2.361,1.195-4.323,3.039-5.682,5.288c-1.683-1.532-3.727-2.654-5.933-3.227c-2.211-0.584-4.595-0.611-6.971-0.005
c-1.006,0.254-1.956,0.621-2.848,1.073c-3.299,1.67-5.742,4.581-6.886,7.984c-1.971-1.145-4.205-1.802-6.475-1.888
c-2.288-0.091-4.625,0.368-6.804,1.471l-0.26,0.132c-1.954,0.99-3.567,2.373-4.799,4.004c-1.243,1.637-2.107,3.521-2.527,5.493
c-1.849-0.823-3.868-1.25-5.924-1.216c-2.055,0.033-4.125,0.513-6.079,1.502c-1.944,0.983-3.567,2.373-4.811,4.01
c-1.232,1.631-2.084,3.51-2.516,5.488c-1.849-0.823-3.879-1.243-5.924-1.216c-2.045,0.027-4.125,0.513-6.08,1.502
c-1.954,0.99-3.567,2.373-4.799,4.004c-1.243,1.637-2.095,3.515-2.527,5.493c-1.849-0.823-3.868-1.25-5.924-1.216
c-2.044,0.027-4.114,0.507-6.069,1.496c-1.954,0.99-3.579,2.378-4.811,4.01c-1.232,1.631-2.095,3.515-2.527,5.494
c-1.849-0.823-3.868-1.25-5.912-1.223c-2.055,0.032-4.137,0.518-6.079,1.502c-1.954,0.99-3.567,2.373-4.811,4.01
c-1.243,1.637-2.095,3.515-2.527,5.493c-1.839-0.829-3.868-1.25-5.912-1.223c-2.044,0.027-4.125,0.513-6.08,1.502
c-1.977,1.001-3.601,2.39-4.844,4.055c-1.249,1.654-2.1,3.561-2.504,5.567c-3.717-1.624-8.066-1.608-11.895,0.33
c-0.249,0.125-0.491,0.263-0.745,0.405c-2.112,1.239-3.785,2.952-4.941,4.928c-1.173,1.971-1.831,4.205-1.928,6.482
c-3.232-0.834-6.701-0.483-9.739,1.055c-1.118,0.566-2.185,1.29-3.16,2.195c-1.81,1.668-3.068,3.696-3.788,5.862
c-0.703,2.172-0.879,4.489-0.493,6.735c-2.35-0.073-4.693,0.43-6.804,1.499c-1.977,1.001-3.748,2.493-5.102,4.442
c-1.405,2.017-2.205,4.252-2.443,6.53c-0.238,2.278,0.085,4.569,0.945,6.688c-1.324,0.245-2.612,0.67-3.821,1.281
c-2.847,1.441-5.213,3.859-6.54,7.042c-1.634,3.92-1.353,8.149,0.425,11.662c0.269,0.531,0.577,1.056,0.92,1.564
c-0.328,0.138-0.638,0.28-0.955,0.441c-3.74,1.892-6.587,5.463-7.409,9.894c-0.574,3.128-0.061,6.189,1.272,8.821
c0.72,1.423,1.678,2.727,2.844,3.825c-1.868,1.3-3.399,3.041-4.437,5.083c-1.043,2.032-1.604,4.346-1.523,6.802
c0.067,2.095,0.598,4.069,1.49,5.831c1.184,2.338,3.01,4.295,5.237,5.636c-1.538,1.673-2.666,3.705-3.25,5.916
c-0.584,2.211-0.629,4.589-0.033,6.971c0.26,1.018,0.622,1.984,1.078,2.888c1.527,3.017,4.064,5.308,7.071,6.568
c-1.164,1.681-1.953,3.6-2.283,5.639c-0.342,2.018-0.224,4.158,0.404,6.266c0.241,0.814,0.552,1.595,0.917,2.318
c1.784,3.524,4.944,6.041,8.596,7.087c-2.008,3.883-2.126,8.611-0.068,12.679c0.252,0.497,0.537,0.977,0.845,1.445
c1.466,2.195,3.45,3.845,5.681,4.887c2.231,1.042,4.707,1.477,7.164,1.256c-0.281,2.669,0.225,5.378,1.43,7.762
c1.018,2.01,2.533,3.798,4.516,5.165c2.165,1.502,4.609,2.294,7.068,2.44c2.447,0.153,4.902-0.352,7.111-1.456
c0.248,0.882,0.58,1.736,0.992,2.549c1.795,3.547,5.081,6.311,9.248,7.282c3.317,0.776,6.649,0.296,9.473-1.134
c1.548-0.784,2.941-1.843,4.101-3.14c1.488,1.958,3.466,3.511,5.735,4.463c2.27,0.952,4.819,1.307,7.433,0.921
c1.516-0.228,2.941-0.694,4.24-1.351c3.151-1.596,5.557-4.33,6.769-7.599c1.999,1.23,4.267,1.927,6.612,2.046
c2.333,0.124,4.732-0.352,6.968-1.484c1.954-0.99,3.579-2.379,4.811-4.01c1.232-1.631,2.095-3.515,2.515-5.488
c1.849,0.823,3.868,1.25,5.924,1.217c2.055-0.032,4.125-0.513,6.08-1.502c1.944-0.983,3.567-2.373,4.811-4.01
c1.232-1.631,2.084-3.51,2.515-5.488c1.849,0.823,3.88,1.243,5.924,1.217c2.044-0.027,4.125-0.513,6.079-1.502
c1.954-0.99,3.567-2.373,4.799-4.004c1.243-1.637,2.095-3.515,2.527-5.494c1.849,0.823,3.868,1.25,5.924,1.217
c2.055-0.032,4.125-0.513,6.08-1.502c1.943-0.983,3.567-2.373,4.799-4.004c1.232-1.631,2.095-3.515,2.527-5.494
c1.849,0.823,3.879,1.243,5.924,1.217c2.044-0.027,4.125-0.513,6.069-1.496c1.954-0.99,3.567-2.373,4.811-4.01
c1.243-1.637,2.095-3.515,2.527-5.494c1.839,0.829,3.868,1.25,5.924,1.217c2.044-0.027,4.114-0.507,6.069-1.496
c1.954-0.99,3.579-2.379,4.811-4.01c1.232-1.631,2.095-3.515,2.527-5.494c1.849,0.823,3.868,1.25,5.912,1.223
c2.055-0.032,4.125-0.513,6.08-1.502c1.943-0.983,3.567-2.373,4.811-4.009c1.232-1.631,2.084-3.51,2.516-5.488
c1.849,0.823,3.879,1.243,5.924,1.217c2.044-0.027,4.125-0.513,6.079-1.501c1.954-0.99,3.567-2.373,4.799-4.004
c1.243-1.637,2.095-3.515,2.527-5.494c1.849,0.823,3.868,1.25,5.924,1.217c2.044-0.027,4.114-0.507,6.069-1.496
c3.909-1.978,6.479-5.536,7.337-9.503c1.849,0.823,3.88,1.243,5.924,1.217c2.044-0.027,4.125-0.513,6.069-1.496
c1.965-0.995,3.59-2.384,4.822-4.016c1.237-1.648,2.095-3.543,2.521-5.533c3.7,1.647,8.077,1.659,11.93-0.29
c0.204-0.102,0.418-0.211,0.615-0.326c2.292-1.302,4.078-3.157,5.274-5.308c1.202-2.141,1.812-4.579,1.772-7.042
c2.97,0.54,6.048,0.089,8.759-1.283c1.717-0.869,3.285-2.117,4.574-3.72c1.644-2.052,2.623-4.436,2.945-6.883
c0.31-2.442-0.013-4.932-0.963-7.204c1.221-0.25,2.392-0.657,3.499-1.217c3.254-1.647,5.861-4.57,7.026-8.325
c1.103-3.595,0.677-7.297-0.894-10.403c-0.629-1.242-1.444-2.405-2.423-3.414c2.072-1.347,3.739-3.212,4.855-5.408
c0.881-1.737,1.403-3.676,1.472-5.726c0.031-0.527,0.029-1.065-0.019-1.608c-0.139-1.874-0.652-3.644-1.452-5.226
c-1.441-2.847-3.824-5.117-6.724-6.417c1.423-2.011,2.295-4.382,2.52-6.824c0.22-2.453-0.206-5.004-1.362-7.372l-0.08-0.158
C375.418,129.644,371.079,126.901,366.423,126.505z"
    />
    <path
      fill="#F2F2F2"
      d="M366.423,126.505c0.992-3.341,0.73-7.025-0.905-10.256c-0.372-0.735-0.817-1.445-1.329-2.122
c-1.351-1.799-3.05-3.167-4.94-4.098c-1.89-0.931-3.954-1.419-6.037-1.443c0.686-3.101,0.297-6.395-1.178-9.309
c-0.578-1.141-1.319-2.213-2.23-3.199c-1.674-1.793-3.701-3.051-5.873-3.754c-2.178-0.714-4.5-0.872-6.741-0.476
c0.068-2.333-0.442-4.658-1.499-6.748c-1.006-1.988-2.521-3.776-4.487-5.137c-2.011-1.395-4.258-2.188-6.541-2.408
c-2.272-0.227-4.586,0.108-6.688,0.973c-0.25-1.307-0.675-2.567-1.276-3.753c-1.452-2.869-3.905-5.246-7.116-6.573
c-3.897-1.618-8.115-1.313-11.606,0.453c-0.554,0.28-1.101,0.6-1.614,0.959c-0.126-0.305-0.258-0.593-0.406-0.887
c-1.904-3.762-5.496-6.627-9.968-7.415c-3.105-0.557-6.139-0.044-8.748,1.277c-1.446,0.731-2.754,1.706-3.87,2.895
c-1.311-1.862-3.063-3.389-5.1-4.415c-2.044-1.038-4.362-1.581-6.802-1.495c-2.077,0.072-4.034,0.608-5.774,1.489
c-2.361,1.195-4.323,3.039-5.682,5.288c-1.683-1.532-3.727-2.654-5.933-3.227c-2.211-0.584-4.595-0.611-6.971-0.005
c-1.006,0.254-1.956,0.621-2.848,1.073c-3.299,1.67-5.742,4.581-6.886,7.984c-1.971-1.145-4.205-1.802-6.475-1.888
c-2.288-0.091-4.625,0.368-6.804,1.471l-0.26,0.132c-1.954,0.99-3.567,2.373-4.799,4.004c-1.243,1.637-2.107,3.521-2.527,5.493
c-1.849-0.823-3.868-1.25-5.924-1.216c-2.055,0.033-4.125,0.513-6.079,1.502c-1.944,0.983-3.567,2.373-4.811,4.01
c-1.232,1.631-2.084,3.51-2.516,5.488c-1.849-0.823-3.879-1.243-5.924-1.216c-2.045,0.027-4.125,0.513-6.08,1.502
c-1.954,0.99-3.567,2.373-4.799,4.004c-1.243,1.637-2.095,3.515-2.527,5.493c-1.849-0.823-3.868-1.25-5.924-1.216
c-2.044,0.027-4.114,0.507-6.069,1.496c-1.954,0.99-3.579,2.378-4.811,4.01c-1.232,1.631-2.095,3.515-2.527,5.494
c-1.849-0.823-3.868-1.25-5.912-1.223c-2.055,0.032-4.137,0.518-6.079,1.502c-1.954,0.99-3.567,2.373-4.811,4.01
c-1.243,1.637-2.095,3.515-2.527,5.493c-1.839-0.829-3.868-1.25-5.912-1.223c-2.044,0.027-4.125,0.513-6.08,1.502
c-1.977,1.001-3.601,2.39-4.844,4.055c-1.249,1.654-2.1,3.561-2.504,5.567c-3.717-1.624-8.066-1.608-11.895,0.33
c-0.249,0.125-0.491,0.263-0.745,0.405c-2.112,1.239-3.785,2.952-4.941,4.928c-1.173,1.971-1.831,4.205-1.928,6.482
c-3.232-0.834-6.701-0.483-9.739,1.055c-1.118,0.566-2.185,1.29-3.16,2.195c-1.81,1.668-3.068,3.696-3.788,5.862
c-0.703,2.172-0.879,4.489-0.493,6.735c-2.35-0.073-4.693,0.43-6.804,1.499c-1.977,1.001-3.748,2.493-5.102,4.442
c-1.405,2.017-2.205,4.252-2.443,6.53c-0.238,2.278,0.085,4.569,0.945,6.688c-1.868,1.3-3.394,3.051-4.437,5.083
c-1.044,2.032-1.598,4.356-1.523,6.802c0.067,2.095,0.598,4.069,1.489,5.831c1.184,2.338,3.01,4.295,5.237,5.637
c-1.549,1.678-2.66,3.716-3.25,5.916c-0.584,2.211-0.634,4.607-0.033,6.971c0.26,1.018,0.622,1.984,1.078,2.888
c1.527,3.017,4.064,5.308,7.071,6.568c-1.153,1.676-1.936,3.605-2.288,5.628c-0.33,2.04-0.229,4.174,0.41,6.278
c0.241,0.814,0.552,1.595,0.917,2.317c1.784,3.524,4.944,6.041,8.596,7.088c-2.008,3.883-2.126,8.612-0.068,12.679
c0.252,0.497,0.537,0.977,0.845,1.445c1.466,2.195,3.45,3.845,5.681,4.887c2.231,1.043,4.707,1.477,7.164,1.256
c-0.281,2.669,0.225,5.378,1.43,7.762c1.018,2.01,2.533,3.798,4.515,5.165c2.175,1.495,4.609,2.294,7.068,2.44
c2.447,0.153,4.913-0.358,7.111-1.456c0.248,0.882,0.58,1.736,0.992,2.548c1.795,3.547,5.081,6.311,9.248,7.282
c3.317,0.776,6.649,0.296,9.473-1.134c1.547-0.784,2.941-1.843,4.101-3.14c1.482,1.975,3.476,3.505,5.735,4.462
c2.27,0.952,4.825,1.319,7.433,0.921c1.516-0.228,2.941-0.694,4.24-1.351c3.151-1.596,5.557-4.331,6.769-7.599
c1.994,1.219,4.274,1.938,6.612,2.046c2.333,0.124,4.732-0.351,6.968-1.484c1.954-0.99,3.567-2.373,4.811-4.01
c1.238-1.62,2.09-3.498,2.515-5.488c1.856,0.835,3.885,1.255,5.924,1.216c2.044-0.027,4.125-0.513,6.08-1.502
c1.954-0.99,3.567-2.373,4.799-4.004c1.249-1.626,2.112-3.51,2.527-5.493c1.856,0.835,3.873,1.261,5.924,1.216
c2.055-0.032,4.125-0.513,6.079-1.502c1.944-0.983,3.567-2.373,4.799-4.004c1.238-1.62,2.1-3.504,2.527-5.493
c1.856,0.835,3.885,1.255,5.924,1.216c2.044-0.027,4.125-0.513,6.08-1.502c1.954-0.99,3.567-2.373,4.799-4.004
c1.249-1.626,2.1-3.504,2.527-5.493c1.856,0.835,3.873,1.261,5.924,1.216c2.044-0.027,4.114-0.507,6.069-1.496
c1.954-0.99,3.579-2.379,4.811-4.01c1.238-1.62,2.1-3.504,2.527-5.493c1.856,0.835,3.873,1.261,5.912,1.223
c2.055-0.032,4.137-0.518,6.079-1.502c1.954-0.99,3.567-2.373,4.811-4.01c1.249-1.626,2.1-3.504,2.527-5.493
c1.844,0.84,3.873,1.261,5.912,1.223c2.044-0.027,4.125-0.513,6.08-1.502c1.955-0.988,3.567-2.373,4.799-4.004
c1.25-1.626,2.112-3.51,2.527-5.493c1.856,0.835,3.873,1.261,5.924,1.216c2.055-0.032,4.125-0.513,6.079-1.502
c1.943-0.983,3.567-2.373,4.799-4.004c1.238-1.62,2.1-3.504,2.527-5.493c1.856,0.835,3.885,1.255,5.924,1.216
c2.044-0.027,4.125-0.513,6.069-1.496c3.909-1.978,6.479-5.536,7.337-9.503c1.856,0.835,3.873,1.261,5.924,1.216
c2.044-0.027,4.114-0.507,6.069-1.496c1.954-0.99,3.579-2.379,4.822-4.016c1.237-1.648,2.1-3.532,2.521-5.533
c3.7,1.647,8.077,1.659,11.93-0.29c0.204-0.102,0.418-0.211,0.615-0.326c2.293-1.302,4.078-3.157,5.274-5.309
c1.202-2.141,1.812-4.579,1.772-7.042c2.97,0.54,6.048,0.09,8.759-1.283c1.717-0.869,3.285-2.117,4.575-3.72
c1.002-1.244,1.743-2.613,2.251-4.048c0.031-0.527,0.029-1.065-0.019-1.608c-0.139-1.874-0.652-3.644-1.452-5.226
c-1.441-2.847-3.824-5.117-6.724-6.417c1.423-2.011,2.295-4.382,2.52-6.824c0.22-2.453-0.206-5.004-1.362-7.372l-0.08-0.158
C375.418,129.644,371.079,126.901,366.423,126.505z"
    />
    <rect
      x="119.409"
      y="56.585"
      transform="matrix(-0.8922 0.4516 -0.4516 -0.8922 449.8699 240.0309)"
      fill="#5E616B"
      width="153.764"
      height="234.23"
    />
    <g>
      <rect
        x="166.742"
        y="254.889"
        transform="matrix(-0.8922 0.4516 -0.4516 -0.8922 581.662 395.5868)"
        fill="#42454C"
        width="153.764"
        height="24.632"
      />

      <rect
        x="75.842"
        y="66.978"
        transform="matrix(-0.8922 0.4516 -0.4516 -0.8922 328.5523 96.8047)"
        fill="#42454C"
        width="153.764"
        height="41.262"
      />
    </g>
    <path
      fill="#FFC34E"
      d="M368.452,486.044c-20.992,10.282-43.593,17.774-67.333,21.985L34.471,127.623l-16.875-24.074
c-2.508-3.574-3.709-7.68-3.709-11.755c0-6.28,2.873-12.466,8.296-16.478c8.934-6.614,21.504-4.911,28.348,3.845l9.488,12.131
L368.452,486.044z"
    />
    <path
      fill="#EA9B28"
      d="M319.279,504.12c-5.966,1.515-12.027,2.821-18.16,3.908h-0.01L34.471,127.623l-16.875-24.074
c-2.508-3.574-3.709-7.68-3.709-11.755c0-6.28,2.873-12.466,8.296-16.478c2.351-1.745,4.963-2.894,7.649-3.511
c-3.448,6.593-3.197,14.806,1.348,21.295l13.197,18.819L319.279,504.12z"
    />
  </svg>
)

const namespace = "asia/japan/customs-in-japan"
const id = "customs-in-japan"
i18n.addResourceBundle("fr", namespace, translationFr)
i18n.addResourceBundle("en", namespace, translationEn)

const SvgContainer = styled.p`
  text-align: center;
  margin-bottom: 0.5rem;
  svg {
    width: 50px;
    height: 50px;
  }
`

const IndexPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const { t } = useCustomTranslation([namespace, "common"])
  const title = t(`common:country.japan.card.${id}`)

  return (
    <>
      <SEO
        title={title}
        fullTitle={t("full-title")}
        socialNetworkDescription={t("social-network-description")}
        googleDescription={t("google-description")}
        image={HomeImgUrl}
        location={location}
      />
      <JapanBlogLayout page={id} location={location}>
        <JapanTitle title={title} linkId={id} />
        <ImageAsLandscape>
          <SharedCardJapanImages image="customsInJapan" />
        </ImageAsLandscape>
        <Quote>{t("quote")}</Quote>
        <Divider />
        <Introduction>{t("introduction.part1")}</Introduction>
        <SectionContent>
          <p>{t("introduction.part2")}</p>
          <p>{t("introduction.part3")}</p>
        </SectionContent>
        <Divider />
        <section>
          <JapanHeadline>{t("transports.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <SvgContainer>
              <Shinkansen />
            </SvgContainer>
            <ul>
              <li>{t("transports.part1")}</li>
              <li>{t("transports.part2")}</li>
              <li>{t("transports.part3")}</li>
              <li>{t("transports.part4")}</li>
              <li>{t("transports.part5")}</li>
            </ul>
          </SectionContent>
        </section>
        <Divider />
        <section>
          <JapanHeadline>{t("activities.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <SvgContainer>
              <Receive />
            </SvgContainer>
            <ul>
              <li>{t("activities.part1")}</li>
              <li>{t("activities.part2")}</li>
              <li>{t("activities.part3")}</li>
              <li>{t("activities.part4")}</li>
              <li>{t("activities.part5")}</li>
              <li>{t("activities.part6")}</li>
              <li>{t("activities.part7")}</li>
              <li>{t("activities.part8")}</li>
            </ul>
          </SectionContent>
        </section>
        <Divider />
        <section>
          <JapanHeadline>{t("restaurant.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <SvgContainer>
              <Sushi />
            </SvgContainer>
            <SectionContent>
              <p>{t("restaurant.part1")}</p>
              <ul>
                <li>{t("restaurant.part2")}</li>
                <li>{t("restaurant.part3")}</li>
                <li>{t("restaurant.part4")}</li>
                <li>{t("restaurant.part5")}</li>
                <li>{t("restaurant.part6")}</li>
                <li>{t("restaurant.part7")}</li>
              </ul>
            </SectionContent>
            <ImageAsPortrait>
              <CustomsInJapanImages image="chopSticks" />
            </ImageAsPortrait>
          </SectionContent>
        </section>
        <Divider />
        <Conclusion>
          <p>{t("conclusion")}</p>
          <ul>
            <li>{t("question")}</li>
          </ul>
        </Conclusion>
        <Divider />
        <Comments
          collectionName={namespace}
          location={location}
          facebookQuote={`${t("facebook.part1")}\n${t("facebook.part2")}`}
        />
      </JapanBlogLayout>
    </>
  )
}

export default IndexPage
